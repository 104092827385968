<template>
  <div class="report-options flex flex-column">
    <p class="card-header-divider">
      Настройки аналитики динамики среднего профиля компании
    </p>
    <div class="report-options__body">
      <custom-select-2
        label="Группа"
        name="group_id"
        v-model="selectedGroup"
        :options="groupOptions"
      ></custom-select-2>
      <custom-input
        type="date"
        v-model="date1"
        name="date_first"
        label="Первая дата"
      />
      <custom-input
        type="date"
        v-model="date2"
        name="date_second"
        label="Вторая дата"
      />
      <p class="text-center mt-15 report-comment">
        Динамика прослеживается в интервалах не менее 3-х месяцев.
      </p>
    </div>
    <div
      v-if="selectedGroup && date1 && date2"
      class="report-options__btn flex justify-content-center"
    >
      <button @click="requestForReport" class="btn btn-accent">
        Получить отчет
      </button>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from "@vue/runtime-core";
import CustomSelect2 from "../../../components/Forms/Fields/CustomSelect2.vue";
import { useStore } from "vuex";
import CustomInput from "../../../components/Forms/Fields/CustomInput.vue";
import { notify } from "@kyvg/vue3-notification";
export default {
  components: { CustomSelect2, CustomInput },
  name: "general-dynamic-report-parametres",
  setup(props, context) {
    const store = useStore();
    const selectedGroup = ref(null),
      groupOptions = ref([]),
      date1 = ref(null),
      date2 = ref(null);
    const groups = computed(() => store.state.group.groups);

    onBeforeMount(() => {
      if (!groups.value)
        store.dispatch("group/getGroups").then(() => {
          groupOptions.value = groups.value.map((group) => {
            return { id: group.id, text: group.name };
          });
        });
      else {
        groupOptions.value = groups.value.map((group) => {
          return { id: group.id, text: group.name };
        });
      }
    });

    const requestForReport = () => {
      const secDate1 = new Date(date1.value),
        secDate2 = new Date(date2.value);
      if (Math.abs(secDate1 - secDate2) >= 7776000000) {
        context.emit("reportRequest", {
          group_id: selectedGroup.value,
          date_first: date1.value,
          date_second: date2.value,
        });
      } else {
        notify({
          type: "warning",
          title: "Интервал между датами должен быть не менее 3-х месяцев",
        });
      }
    };

    return {
      selectedGroup,
      groupOptions,
      groups,
      requestForReport,
      date1,
      date2,
    };
  },
};
</script>
