<template>
  <div
    v-if="genderData && ageData"
    class="group-report report flex flex-column top-divider"
  >
    <div class="report-title">
      <span>Демографическая аналитика для одной группы респондентов</span>
      <button @click="print" class="btn btn-accent">Печать</button>
    </div>
    <div class="report-block">
      <div class="report-title lvl-2 main-title">
        1 Сопоставление средних показателей в группах, разделенных по полу и
        возрасту
      </div>
      <div v-html="descriptions.average" class="report-description"></div>
      <div class="report-chart">
        <p class="report-chart__demography">Группы по полу</p>
        <div class="flex flex-wrap justify-content-center">
          <group-cards :groupsData="genderData.average" />
        </div>
        <consultation />
      </div>
      <div class="report-chart">
        <p class="report-chart__demography">Группы по возрасту</p>
        <div class="flex flex-wrap justify-content-center">
          <group-cards :groupsData="ageData.average" />
        </div>
        <consultation />
      </div>
    </div>
    <div class="report-block">
      <div class="report-title lvl-2">
        2 Сопоставление показателей по общему ЭИ в группах, разделенных по полу
        и возрасту
      </div>
      <div class="report-content flex flex-wrap justify-content-center">
        <div v-html="descriptions.total" class="report-description"></div>
        <div class="report-chart">
          <p class="report-chart__demography">Группы по полу</p>
          <bar-chart
            :chartLabels="levelDictionary"
            :legend="false"
            :chartData="barTotalData"
            htmlLegend="group-bar-total"
          />
          <div class="chart-legend" id="group-bar-total"></div>
          <consultation />
        </div>
        <div class="report-chart">
          <p class="report-chart__demography">Группы по возрасту</p>
          <bar-chart
            :chartLabels="levelDictionary"
            :legend="false"
            :chartData="barTotalDataReserved"
            htmlLegend="group-bar-total-reserved"
          />
          <div class="chart-legend" id="group-bar-total-reserved"></div>
          <consultation />
        </div>
      </div>
    </div>
    <div class="report-block">
      <div class="report-title lvl-2">
        3 Сопоставление показателей по доменам ЭИ в группах, разделенных по полу
        и возрасту
      </div>
      <div class="report-content flex flex-wrap justify-content-center">
        <div v-html="descriptions.domains" class="report-description"></div>
        <div class="report-chart">
          <p class="report-chart__demography">Группы по полу</p>
          <bar-chart
            :chartLabels="levelDictionary"
            :chartData="barDomain1Data"
            :legend="false"
            :htmlLegend="'group-bar-domain1'"
          />
          <div class="chart-legend" id="group-bar-domain1"></div>
          <consultation />
        </div>
        <div class="report-chart">
          <bar-chart
            :chartLabels="levelDictionary"
            :chartData="barDomain2Data"
            :htmlLegend="'group-bar-domain2'"
          />
          <div class="chart-legend" id="group-bar-domain2"></div>
          <consultation />
        </div>
        <div class="report-chart">
          <p class="report-chart__demography">Группы по возрасту</p>
          <bar-chart
            :chartLabels="levelDictionary"
            :chartData="barDomain1DataReserved"
            :legend="false"
            htmlLegend="group-bar-domain1-reserved"
          />
          <div class="chart-legend" id="group-bar-domain1-reserved"></div>
          <consultation />
        </div>
        <div class="report-chart">
          <bar-chart
            :chartLabels="levelDictionary"
            :chartData="barDomain2DataReserved"
            htmlLegend="group-bar-domain2-reserved"
          />
          <div class="chart-legend" id="group-bar-domain2-reserved"></div>
          <consultation />
        </div>
      </div>
    </div>
    <div class="report-block">
      <div class="report-title lvl-2">
        4 Сопоставление соотношения доменов ЭИ в группах, разделенных по полу и
        возрасту
      </div>
      <div class="report-content flex flex-wrap justify-content-center">
        <div
          v-html="descriptions.dominateDomains"
          class="report-description"
        ></div>
        <div class="flex flex-column w-full complex-chart report-chart">
          <p class="report-chart__demography">Группы по полу</p>
          <div class="flex complex-chart__container">
            <div class="complex-chart__element">
              <p>{{ group1 }}</p>
              <doughnut-chart
                :chartLabels="[
                  'Опытный домен выше стратегического',
                  'Стратегический домен выше опытного',
                  'Нет доминирующего домена',
                ]"
                htmlLegend="group-doughnut-dominate-branches"
                :chartData="doughnutDominateDomains1"
                :isComplex="true"
              />
              <div
                class="chart-legend print"
                id="group-doughnut-dominate-branches"
              ></div>
            </div>

            <div class="complex-chart__element">
              <p>{{ group2 }}</p>
              <doughnut-chart
                :chartLabels="[
                  'Опытный домен выше стратегического',
                  'Стратегический домен выше опытного',
                  'Нет доминирующего домена',
                ]"
                htmlLegend="group-doughnut-dominate-branches2"
                :chartData="doughnutDominateDomains2"
                :isComplex="true"
              />
            </div>
          </div>
          <div
            class="chart-legend"
            id="group-doughnut-dominate-branches2"
          ></div>
          <consultation />
        </div>
        <div class="flex flex-column w-full complex-chart report-chart">
          <p class="report-chart__demography">Группы по возрасту</p>
          <div class="flex complex-chart__container">
            <div class="complex-chart__element">
              <p>{{ age1 }}</p>
              <doughnut-chart
                :chartLabels="[
                  'Опытный домен выше стратегического',
                  'Стратегический домен выше опытного',
                  'Нет доминирующего домена',
                ]"
                htmlLegend="group-doughnut-dominate-branches-reserved"
                :chartData="doughnutDominateDomains1Reserved"
                :isComplex="true"
              />
            </div>
            <div class="complex-chart__element">
              <p>{{ age2 }}</p>
              <doughnut-chart
                :chartLabels="[
                  'Опытный домен выше стратегического',
                  'Стратегический домен выше опытного',
                  'Нет доминирующего домена',
                ]"
                :chartData="doughnutDominateDomains2Reserved"
                :isComplex="true"
              />
            </div>
            <div class="complex-chart__element">
              <p>{{ age3 }}</p>
              <doughnut-chart
                :chartLabels="[
                  'Опытный домен выше стратегического',
                  'Стратегический домен выше опытного',
                  'Нет доминирующего домена',
                ]"
                :chartData="doughnutDominateDomains3Reserved"
                :isComplex="true"
              />
            </div>
            <div class="complex-chart__element">
              <p>{{ age4 }}</p>
              <doughnut-chart
                :chartLabels="[
                  'Опытный домен выше стратегического',
                  'Стратегический домен выше опытного',
                  'Нет доминирующего домена',
                ]"
                :chartData="doughnutDominateDomains4Reserved"
                :isComplex="true"
              />
            </div>
          </div>
          <div
            class="chart-legend"
            id="group-doughnut-dominate-branches-reserved"
          ></div>
          <consultation />
        </div>
        <div class="report-chart">
          <p class="report-chart__demography">Группы по полу</p>
          <bar-chart
            :chartLabels="[
              'Опытный домен выше стратегического',
              'Стратегический домен выше опытного',
              'Домены равны',
            ]"
            :chartData="barDominateDomains"
            htmlLegend="group-bar-dominate-domains"
          />
          <div class="chart-legend" id="group-bar-dominate-domains"></div>
          <consultation />
        </div>
        <div class="report-chart">
          <p class="report-chart__demography">Группы по возрасту</p>
          <bar-chart
            :chartLabels="[
              'Опытный домен выше стратегического',
              'Стратегический домен выше опытного',
              'Домены равны',
            ]"
            :chartData="barDominateDomainsReserved"
            htmlLegend="group-bar-dominate-domains-reserved"
          />
          <div
            class="chart-legend"
            id="group-bar-dominate-domains-reserved"
          ></div>
          <consultation />
        </div>
      </div>
    </div>
    <div class="report-block">
      <div class="report-title lvl-2">
        5 Сопоставление показателей по ветвям ЭИ в группах, разделенных по полу
        и возрасту
      </div>
      <div class="report-content flex flex-wrap justify-content-center">
        <div v-html="descriptions.branches" class="report-description"></div>
        <div class="report-chart">
          <p class="report-chart__demography">Группы по полу</p>
          <bar-chart
            :chartLabels="levelDictionary"
            :chartData="barBranch1"
            htmlLegend="group-bar-branch1"
          />
          <div class="chart-legend" id="group-bar-branch1"></div>
          <consultation />
        </div>
        <div class="report-chart">
          <bar-chart
            :chartLabels="levelDictionary"
            :chartData="barBranch2"
            htmlLegend="group-bar-branch2"
          />
          <div class="chart-legend" id="group-bar-branch2"></div>
          <consultation />
        </div>
        <div class="report-chart">
          <bar-chart
            :chartLabels="levelDictionary"
            :chartData="barBranch3"
            htmlLegend="group-bar-branch3"
          />
          <div class="chart-legend" id="group-bar-branch3"></div>
          <consultation />
        </div>
        <div class="report-chart">
          <bar-chart
            :chartLabels="levelDictionary"
            :chartData="barBranch4"
            htmlLegend="group-bar-branch4"
          />
          <div class="chart-legend" id="group-bar-branch4"></div>
          <consultation />
        </div>

        <div class="report-chart">
          <p class="report-chart__demography">Группы по возрасту</p>
          <bar-chart
            :chartLabels="levelDictionary"
            :chartData="barBranch1Reserved"
            htmlLegend="group-bar-branch1-reserved"
          />
          <div class="chart-legend" id="group-bar-branch1-reserved"></div>
          <consultation />
        </div>
        <div class="report-chart">
          <bar-chart
            :chartLabels="levelDictionary"
            :chartData="barBranch2Reserved"
            htmlLegend="group-bar-branch2-reserved"
          />
          <div class="chart-legend" id="group-bar-branch2-reserved"></div>
          <consultation />
        </div>
        <div class="report-chart">
          <bar-chart
            :chartLabels="levelDictionary"
            :chartData="barBranch3Reserved"
            htmlLegend="group-bar-branch3-reserved"
          />
          <div class="chart-legend" id="group-bar-branch3-reserved"></div>
          <consultation />
        </div>
        <div class="report-chart">
          <bar-chart
            :chartLabels="levelDictionary"
            :chartData="barBranch4Reserved"
            htmlLegend="group-bar-branch4-reserved"
          />
          <div class="chart-legend" id="group-bar-branch4-reserved"></div>
          <consultation />
        </div>
      </div>
    </div>
    <div class="report-block">
      <div class="report-title lvl-2">6 Сопоставление сильных сторон</div>
      <div class="report-content flex flex-wrap justify-content-center">
        <div
          v-html="descriptions.dominateBranches"
          class="report-description"
        ></div>
        <div class="report-chart print-hide">
          <p class="report-chart__demography">Группы по полу</p>
          <div class="grid three-block">
            <p class="three-block__label1">{{ group1 }}</p>
            <p class="three-block__labelempty"></p>
            <p class="three-block__label2">{{ group2 }}</p>
            <bar-chart
              class="three-block__chart1"
              :reverseX="true"
              :chartLabels="dominateBranchesLabels"
              :legend="false"
              :chartData="barDominateBranchesCountData1"
              :vertical="true"
              :ticksShow="false"
              :minHeight="220"
              htmlLegend="group-bar-dominate-branches-count"
            />
            <div class="three-block__legend">
              <p v-for="(label, index) of dominateBranchesLabels" :key="index">
                {{ label }}
              </p>
            </div>
            <bar-chart
              class="three-block__chart2"
              :chartLabels="dominateBranchesLabels"
              :legend="false"
              :chartData="barDominateBranchesCountData2"
              :vertical="true"
              :ticksShow="false"
              :minHeight="220"
            />
          </div>
          <div
            class="chart-legend"
            id="group-bar-dominate-branches-count"
          ></div>
          <consultation />
        </div>

        <div class="report-chart print-non-visibility">
          <p class="report-chart__demography">Группы по полу</p>
          <p class="bold-label">{{ group1 }}</p>
          <bar-chart
            :chartLabels="dominateBranchesLabels"
            :legend="false"
            :chartData="barDominateBranchesCountData1"
            htmlLegend="group-bar-dominate-branches-count2"
          />
          <div
            class="chart-legend"
            id="group-bar-dominate-branches-count2"
          ></div>
        </div>
        <div class="report-chart print-non-visibility">
          <p class="bold-label">{{ group2 }}</p>
          <bar-chart
            :chartLabels="dominateBranchesLabels"
            :legend="false"
            :chartData="barDominateBranchesCountData2"
            htmlLegend="group-bar-dominate-branches-count3"
          />
          <div
            class="chart-legend"
            id="group-bar-dominate-branches-count3"
          ></div>
        </div>

        <div class="report-chart">
          <p class="report-chart__demography">Группы по возрасту</p>
          <div class="flex multiple-charts">
            <div
              class="
                multiple-charts__labels
                flex flex-column
                justify-content-around
              "
            >
              <p
                v-for="(branch, index) in getBranchesLabels(
                  ageData.dominateBranches
                )"
                :key="index"
              >
                {{ branch }}
              </p>
            </div>
            <div class="multiple-charts__charts">
              <bar-chart
                v-for="branch of getBranchesKeys(ageData.dominateBranches)"
                :key="branch"
                :chartLabels="[keysToLabels(branch)]"
                :legend="false"
                :chartData="
                  getDominateBranchesDataReserved(
                    ageData.dominateBranches,
                    branch
                  )
                "
                :vertical="true"
                :minHeight="220"
                :ticksShow="false"
                :label="keysToLabels(branch)"
                htmlLegend="group-bar-dominate-branches-count-reserved"
              />
            </div>
            <div
              class="chart-legend multiple-charts__legend"
              id="group-bar-dominate-branches-count-reserved"
            ></div>
          </div>

          <consultation />
        </div>
        <div class="report-chart">
          <p class="report-chart__demography">Группы по полу</p>
          <bar-chart
            :chartLabels="Object.values(branchesDictionary)"
            :chartData="barDominateBranchesAverageData"
            htmlLegend="group-bar-dominate-branches-average"
          />
          <div
            class="chart-legend"
            id="group-bar-dominate-branches-average"
          ></div>
          <consultation />
        </div>
        <div class="report-chart">
          <p class="report-chart__demography">Группы по возрасту</p>
          <bar-chart
            :chartLabels="Object.values(branchesDictionary)"
            :chartData="barDominateBranchesAverageDataReserved"
            htmlLegend="group-bar-dominate-branches-average-reserved"
          />
          <div
            class="chart-legend"
            id="group-bar-dominate-branches-average-reserved"
          ></div>
          <consultation />
        </div>
        <div class="report-chart print-hide">
          <p class="report-chart__demography">Группы по полу</p>
          <div class="grid three-block">
            <p class="three-block__label1">{{ group1 }}</p>
            <p class="three-block__labelempty print-hide"></p>
            <p class="three-block__label2 print-hide">{{ group2 }}</p>
            <bar-chart
              class="three-block__chart1"
              :reverseX="true"
              :chartLabels="Object.values(branchesDictionary)"
              :legend="false"
              :chartData="barDominateBranchesRespondentsCountData1"
              :vertical="true"
              :stacked="true"
              :ticksShow="false"
              :minHeight="220"
              htmlLegend="group-bar-dominate-branches-respondents"
            />
            <div class="three-block__legend">
              <p
                v-for="(label, index) of Object.values(branchesDictionary)"
                :key="index"
              >
                {{ label }}
              </p>
            </div>
            <p class="three-block__label2 print">{{ group2 }}</p>
            <bar-chart
              class="three-block__chart2"
              :chartLabels="Object.values(branchesDictionary)"
              :legend="false"
              :stacked="true"
              :chartData="barDominateBranchesRespondentsCountData2"
              :vertical="true"
              :ticksShow="false"
              :customWidth="360"
              :minHeight="220"
            />
          </div>
          <div
            class="chart-legend"
            id="group-bar-dominate-branches-respondents"
          ></div>
          <div class="chart-comment">
            *Сумма доминирующих ветвей может быть больше количества респондентов
            в связи с тем, что у некоторых респондентов могут доминировать сразу
            две ветви.
          </div>
          <consultation />
        </div>

        <div class="report-chart print-non-visibility">
          <p class="report-chart__demography">Группы по полу</p>
          <p class="bold-label">{{ group1 }}</p>
          <bar-chart
            :chartLabels="Object.values(branchesDictionary)"
            :legend="false"
            :chartData="barDominateBranchesRespondentsCountData1"
            :stacked="true"
            :ticksShow="true"
            htmlLegend="group-bar-dominate-branches-respondents2"
          />
          <div
            class="chart-legend"
            id="group-bar-dominate-branches-respondents2"
          ></div>
        </div>
        <div class="report-chart print-non-visibility">
          <p class="bold-label">{{ group2 }}</p>
          <bar-chart
            class="three-block__chart2"
            :chartLabels="Object.values(branchesDictionary)"
            :legend="false"
            :stacked="true"
            htmlLegend="group-bar-dominate-branches-respondents3"
            :chartData="barDominateBranchesRespondentsCountData2"
          />
          <div
            class="chart-legend"
            id="group-bar-dominate-branches-respondents3"
          ></div>
          <div class="chart-comment">
            *Сумма доминирующих ветвей может быть больше количества респондентов
            в связи с тем, что у некоторых респондентов могут доминировать сразу
            две ветви.
          </div>
        </div>

        <div class="report-chart">
          <p class="report-chart__demography">Группы по возрасту</p>
          <div class="flex multiple-charts multiple-row">
            <div class="multiple-charts__charts flex">
              <bar-chart
                v-for="branch of getBranchesKeys(ageData.dominateBranches)"
                :key="branch"
                :chartLabels="[
                  'От 18 до 25',
                  'От 26 до 35',
                  'От 36 до 55',
                  'От 56 и старше',
                ]"
                :legend="false"
                :stacked="true"
                :fullWidth="true"
                :chartData="
                  getBranchesRespondentsCountReserved(
                    ageData.dominateBranches,
                    branch
                  )
                "
                htmlLegend="group-bar-dominate-branches-respondents-reserved"
              />
            </div>
          </div>
          <div
            class="chart-legend"
            id="group-bar-dominate-branches-respondents-reserved"
          ></div>
          <div class="chart-comment">
            *Сумма доминирующих ветвей может быть больше количества респондентов
            в связи с тем, что у некоторых респондентов могут доминировать сразу
            две ветви.
          </div>
          <consultation />
        </div>
      </div>
    </div>
    <div class="report-block">
      <div class="report-title lvl-2">
        7 Сопоставление зон потенциального роста
      </div>
      <div class="report-content flex flex-wrap justify-content-center">
        <div
          v-html="descriptions.growingBranches"
          class="report-description"
        ></div>
        <div class="report-chart print-hide">
          <p class="report-chart__demography">Группы по полу</p>
          <div class="grid three-block">
            <p class="three-block__label1">{{ group1 }}</p>
            <p class="three-block__labelempty print-hide"></p>
            <p class="three-block__label2 print-hide">{{ group2 }}</p>
            <bar-chart
              class="three-block__chart1"
              :reverseX="true"
              :chartLabels="growingBranchesLabels"
              :legend="false"
              :chartData="barGrowingBranchesCountData1"
              :vertical="true"
              :ticksShow="false"
              :minHeight="220"
              htmlLegend="group-bar-growing-branches-count"
            />
            <div class="three-block__legend">
              <p v-for="(label, index) of growingBranchesLabels" :key="index">
                {{ label }}
              </p>
            </div>
            <p class="three-block__label2 print">{{ group2 }}</p>
            <bar-chart
              class="three-block__chart2"
              :chartLabels="growingBranchesLabels"
              :legend="false"
              :chartData="barGrowingBranchesCountData2"
              :vertical="true"
              :ticksShow="false"
              :minHeight="220"
            />
          </div>
          <div class="chart-legend" id="group-bar-growing-branches-count"></div>
          <consultation />
        </div>

        <div class="report-chart print-non-visibility">
          <p class="report-chart__demography">Группы по полу</p>
          <p class="bold-label">{{ group1 }}</p>
          <bar-chart
            :chartLabels="growingBranchesLabels"
            :legend="false"
            :chartData="barGrowingBranchesCountData1"
            htmlLegend="group-bar-growing-branches-count2"
          />
          <div
            class="chart-legend"
            id="group-bar-growing-branches-count2"
          ></div>
        </div>
        <div class="report-chart print-non-visibility">
          <p class="bold-label">{{ group2 }}</p>
          <bar-chart
            class="three-block__chart2"
            :chartLabels="growingBranchesLabels"
            :legend="false"
            :chartData="barGrowingBranchesCountData2"
            htmlLegend="group-bar-growing-branches-count3"
          />
          <div
            class="chart-legend"
            id="group-bar-growing-branches-count3"
          ></div>
        </div>

        <div class="report-chart">
          <p class="report-chart__demography">Группы по возрасту</p>
          <div class="flex multiple-charts">
            <div
              class="
                multiple-charts__labels
                flex flex-column
                justify-content-around
              "
            >
              <p
                v-for="(branch, index) in getBranchesLabels(
                  ageData.growingBranches
                )"
                :key="index"
              >
                {{ branch }}
              </p>
            </div>
            <div class="multiple-charts__charts">
              <bar-chart
                v-for="branch of getBranchesKeys(ageData.growingBranches)"
                :key="branch"
                :chartLabels="[keysToLabels(branch)]"
                :legend="false"
                :chartData="
                  getDominateBranchesDataReserved(
                    ageData.growingBranches,
                    branch
                  )
                "
                :ticksShow="false"
                :vertical="true"
                :label="keysToLabels(branch)"
                :minHeight="200"
                htmlLegend="group-bar-growing-branches-count-reserved"
              />
            </div>
            <div
              class="chart-legend multiple-charts__legend"
              id="group-bar-growing-branches-count-reserved"
            ></div>
          </div>

          <consultation />
        </div>
        <div class="report-chart">
          <p class="report-chart__demography">Группы по полу</p>
          <bar-chart
            :chartLabels="Object.values(branchesDictionary)"
            :chartData="barGrowingBranchesAverageData"
            htmlLegend="group-bar-growing-branches-average"
          />
          <div
            class="chart-legend"
            id="group-bar-growing-branches-average"
          ></div>
          <consultation />
        </div>
        <div class="report-chart">
          <p class="report-chart__demography">Группы по возрасту</p>
          <bar-chart
            :chartLabels="Object.values(branchesDictionary)"
            :chartData="barGrowingBranchesAverageDataReserved"
            htmlLegend="group-bar-growing-branches-average-reserved"
          />
          <div
            class="chart-legend"
            id="group-bar-growing-branches-average-reserved"
          ></div>
          <consultation />
        </div>
        <div class="report-chart print-hide">
          <p class="report-chart__demography">Группы по полу</p>
          <div class="grid three-block">
            <p class="three-block__label1">{{ group1 }}</p>
            <p class="three-block__labelempty print-hide"></p>
            <p class="three-block__label2 print-hide">{{ group2 }}</p>
            <bar-chart
              class="three-block__chart1"
              :reverseX="true"
              :chartLabels="Object.values(branchesDictionary)"
              :legend="false"
              :chartData="barGrowingBranchesRespondentsCountData1"
              :vertical="true"
              :stacked="true"
              :ticksShow="false"
              :customWidth="360"
              :minHeight="220"
              htmlLegend="group-bar-growing-branches-respondents"
            />
            <div class="three-block__legend">
              <p
                v-for="(label, index) of Object.values(branchesDictionary)"
                :key="index"
              >
                {{ label }}
              </p>
            </div>
            <p class="three-block__label2 print">{{ group2 }}</p>
            <bar-chart
              class="three-block__chart2"
              :chartLabels="Object.values(branchesDictionary)"
              :legend="false"
              :stacked="true"
              :chartData="barGrowingBranchesRespondentsCountData2"
              :vertical="true"
              :ticksShow="false"
              :minHeight="220"
            />
          </div>
          <div
            class="chart-legend"
            id="group-bar-growing-branches-respondents"
          ></div>
          <div class="chart-comment">
            *Сумма ветвей потенциального роста может быть больше количества
            респондентов в связи с тем, что у некоторых респондентов в зоне
            роста могут находиться сразу две ветви.
          </div>
          <consultation />
        </div>

        <div class="report-chart print-non-visibility">
          <p class="report-chart__demography">Группы по полу</p>
          <p class="bold-label">{{ group1 }}</p>
          <bar-chart
            :chartLabels="Object.values(branchesDictionary)"
            :legend="false"
            :chartData="barGrowingBranchesRespondentsCountData1"
            :stacked="true"
            htmlLegend="group-bar-growing-branches-respondents2"
          />
          <div
            class="chart-legend"
            id="group-bar-growing-branches-respondents2"
          ></div>
        </div>
        <div class="report-chart print-non-visibility">
          <p class="bold-label">{{ group2 }}</p>
          <bar-chart
            :chartLabels="Object.values(branchesDictionary)"
            :legend="false"
            :stacked="true"
            :chartData="barGrowingBranchesRespondentsCountData2"
            htmlLegend="group-bar-growing-branches-respondents3"
          />
          <div
            class="chart-legend"
            id="group-bar-growing-branches-respondents3"
          ></div>
          <div class="chart-comment">
            *Сумма ветвей потенциального роста может быть больше количества
            респондентов в связи с тем, что у некоторых респондентов в зоне
            роста могут находиться сразу две ветви.
          </div>
        </div>

        <div class="report-chart">
          <p class="report-chart__demography">Группы по возрасту</p>
          <div class="flex multiple-charts multiple-row">
            <div class="multiple-charts__charts flex">
              <bar-chart
                v-for="branch of getBranchesKeys(ageData.dominateBranches)"
                :key="branch"
                :chartLabels="[
                  'От 18 до 25',
                  'От 26 до 35',
                  'От 36 до 55',
                  'От 56 и старше',
                ]"
                :legend="false"
                :stacked="true"
                :fullWidth="true"
                :chartData="
                  getBranchesRespondentsCountReserved(
                    ageData.growingBranches,
                    branch
                  )
                "
                htmlLegend="group-bar-dominate-branches-respondents-reserved"
              />
            </div>
          </div>
          <div
            class="chart-legend"
            id="group-bar-dominate-branches-respondents-reserved"
          ></div>
          <div class="chart-comment">
            *Сумма доминирующих ветвей может быть больше количества респондентов
            в связи с тем, что у некоторых респондентов могут доминировать сразу
            две ветви.
          </div>
          <consultation />
        </div>
      </div>
    </div>
    <div class="report-block">
      <div class="report-title lvl-2">
        8 Сопоставление гармонических профилей
      </div>
      <div class="report-content flex flex-wrap justify-content-center">
        <div v-html="descriptions.harmonious" class="report-description"></div>
        <div class="complex-chart report-chart">
          <p class="report-chart__demography">Группы по полу</p>
          <div class="flex complex-chart__container">
            <div class="complex-chart__element">
              <p>{{ group1 }}</p>
              <doughnut-chart
                :chartLabels="[
                  'Гармонические профили',
                  'Негармонические профили',
                ]"
                htmlLegend="group-doughnut-harmonic"
                :chartData="[
                  genderData.harmonious[group1].count.harmonious,
                  genderData.harmonious[group1].count.inharmonious,
                ]"
                :isComplex="true"
              />
              <div
                class="chart-legend print"
                id="group-doughnut-harmonic"
              ></div>
            </div>

            <div class="complex-chart__element">
              <p>{{ group2 }}</p>
              <doughnut-chart
                :chartLabels="[
                  'Гармонические профили',
                  'Негармонические профили',
                ]"
                :chartData="[
                  genderData.harmonious[group2].count.harmonious,
                  genderData.harmonious[group2].count.inharmonious,
                ]"
                :isComplex="true"
                htmlLegend="group-doughnut-harmonic2"
              />
            </div>
          </div>
          <div class="chart-legend" id="group-doughnut-harmonic2"></div>
          <consultation />
        </div>
        <div class="flex flex-column w-full complex-chart report-chart">
          <p class="report-chart__demography">Группы по возрасту</p>
          <div class="flex complex-chart__container">
            <div class="complex-chart__element">
              <p>{{ age1 }}</p>
              <doughnut-chart
                :chartLabels="[
                  'Гармонические профили',
                  'Негармонические профили',
                ]"
                htmlLegend="group-doughnut-harmonic-reserved"
                :chartData="[
                  ageData.harmonious[age1].count.harmonious,
                  ageData.harmonious[age1].count.inharmonious,
                ]"
                :isComplex="true"
              />
            </div>
            <div class="complex-chart__element">
              <p>{{ age2 }}</p>
              <doughnut-chart
                :chartLabels="[
                  'Гармонические профили',
                  'Негармонические профили',
                ]"
                :chartData="[
                  ageData.harmonious[age2].count.harmonious,
                  ageData.harmonious[age2].count.inharmonious,
                ]"
                :isComplex="true"
              />
            </div>
            <div class="complex-chart__element">
              <p>{{ age3 }}</p>
              <doughnut-chart
                :chartLabels="[
                  'Гармонические профили',
                  'Негармонические профили',
                ]"
                :chartData="[
                  ageData.harmonious[age3].count.harmonious,
                  ageData.harmonious[age3].count.inharmonious,
                ]"
                :isComplex="true"
              />
            </div>
            <div class="complex-chart__element">
              <p>{{ age4 }}</p>
              <doughnut-chart
                :chartLabels="[
                  'Гармонические профили',
                  'Негармонические профили',
                ]"
                :chartData="[
                  ageData.harmonious[age4].count.harmonious,
                  ageData.harmonious[age4].count.inharmonious,
                ]"
                :isComplex="true"
              />
            </div>
          </div>
          <div class="chart-legend" id="group-doughnut-harmonic-reserved"></div>
          <consultation />
        </div>
        <div class="report-chart print-hide">
          <p class="report-chart__demography">Группы по полу</p>
          <div class="grid three-block">
            <p class="three-block__label1">{{ group1 }}</p>
            <p class="three-block__labelempty"></p>
            <p class="three-block__label2">{{ group2 }}</p>
            <bar-chart
              :chartLabels="levelDictionary"
              :chartData="barHarmoniusData1"
              :vertical="true"
              :reverseX="true"
              :ticksShow="false"
              :customWidth="400"
              :legend="false"
              :minHeight="220"
              htmlLegend="group-bar-harmonic"
              class="three-block__chart1"
            />
            <div class="three-block__legend">
              <p v-for="(label, index) of levelDictionary" :key="index">
                {{ label }}
              </p>
            </div>
            <p class="three-block__label2 print">{{ group2 }}</p>
            <bar-chart
              :chartLabels="levelDictionary"
              :chartData="barHarmoniusData2"
              :vertical="true"
              :ticksShow="false"
              :customWidth="400"
              :legend="false"
              :minHeight="220"
              class="three-block__chart2"
            />
          </div>
          <div class="chart-legend" id="group-bar-harmonic"></div>
          <consultation />
        </div>

        <div class="report-chart print-non-visibility">
          <p class="report-chart__demography">Группы по полу</p>
          <p class="bold-label">{{ group1 }}</p>
          <bar-chart
            :chartLabels="levelDictionary"
            :chartData="barHarmoniusData1"
            :legend="false"
            htmlLegend="group-bar-harmonic2"
          />
          <div class="chart-legend" id="group-bar-harmonic2"></div>
        </div>
        <div class="report-chart print-non-visibility">
          <p class="bold-label">{{ group2 }}</p>
          <bar-chart
            :chartLabels="levelDictionary"
            :chartData="barHarmoniusData2"
            :legend="false"
            htmlLegend="group-bar-harmonic3"
          />
          <div class="chart-legend" id="group-bar-harmonic3"></div>
        </div>

        <div class="report-chart">
          <p class="report-chart__demography">Группы по возрасту</p>

          <bar-chart
            :chartLabels="levelDictionary"
            :chartData="barHarmoniusDataReserved"
            :legend="false"
            htmlLegend="group-bar-harmonic-reserved"
            class="three-block__chart1"
          />

          <div class="chart-legend" id="group-bar-harmonic-reserved"></div>
          <consultation />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";
import GroupCards from "../charts/GroupCards.vue";
import BarChart from "../charts/BarChart.vue";
import useAnalyticsFunctions from "./../mixins/useAnalyticsFunctions";
import DoughnutChart from "../charts/DoughnutChart.vue";
import Consultation from "./../charts/details/Consultation.vue";
export default {
  components: { GroupCards, BarChart, DoughnutChart, Consultation },
  name: "demographic-report",
  setup() {
    const store = useStore(),
      {
        checkScores,
        keysToLabels,
        dictionary,
        levelDictionary,
        branchesDictionary,
      } = useAnalyticsFunctions();
    const genderData = computed(() => store.state.analytics.reportData),
      ageData = computed(() => store.state.analytics.reportReservedData),
      descriptions = computed(() => genderData.value.block_descriptions),
      group1 = computed(() => Object.keys(genderData.value.average)[0]),
      group2 = computed(() => Object.keys(genderData.value.average)[1]),
      age1 = computed(() => Object.keys(ageData.value.average)[0]),
      age2 = computed(() => Object.keys(ageData.value.average)[1]),
      age3 = computed(() => Object.keys(ageData.value.average)[2]),
      age4 = computed(() => Object.keys(ageData.value.average)[3]);
    const barTotalData = computed(() =>
        getBarTotalData(genderData.value.total)
      ),
      barTotalDataReserved = computed(() =>
        getBarTotalData(ageData.value.total)
      ),
      barDomain1Data = computed(() =>
        getBarDomainsData(genderData.value.domains, "Опытный домен", 1)
      ),
      barDomain2Data = computed(() =>
        getBarDomainsData(genderData.value.domains, "Стратегический домен", 2)
      ),
      barDomain1DataReserved = computed(() =>
        getBarDomainsData(ageData.value.domains, "Опытный домен", 1)
      ),
      barDomain2DataReserved = computed(() =>
        getBarDomainsData(ageData.value.domains, "Стратегический домен", 2)
      ),
      doughnutDominateDomains1 = computed(() =>
        getDataOfGroupDominateDomains(genderData.value.dominateDomains, 0)
      ),
      doughnutDominateDomains2 = computed(() =>
        getDataOfGroupDominateDomains(genderData.value.dominateDomains, 1)
      ),
      doughnutDominateDomains1Reserved = computed(() =>
        getDataOfGroupDominateDomains(ageData.value.dominateDomains, 0)
      ),
      doughnutDominateDomains2Reserved = computed(() =>
        getDataOfGroupDominateDomains(ageData.value.dominateDomains, 1)
      ),
      doughnutDominateDomains3Reserved = computed(() =>
        getDataOfGroupDominateDomains(ageData.value.dominateDomains, 2)
      ),
      doughnutDominateDomains4Reserved = computed(() =>
        getDataOfGroupDominateDomains(ageData.value.dominateDomains, 3)
      ),
      barDominateDomains = computed(() =>
        getBarDominateDomains(genderData.value.dominateDomains)
      ),
      barDominateDomainsReserved = computed(() =>
        getBarDominateDomains(genderData.value.dominateDomains)
      ),
      barBranch1 = computed(() =>
        getBarBranchesData(genderData.value.branches, "branch_1")
      ),
      barBranch2 = computed(() =>
        getBarBranchesData(genderData.value.branches, "branch_2")
      ),
      barBranch3 = computed(() =>
        getBarBranchesData(genderData.value.branches, "branch_3")
      ),
      barBranch4 = computed(() =>
        getBarBranchesData(genderData.value.branches, "branch_4")
      ),
      barBranch1Reserved = computed(() =>
        getBarBranchesData(ageData.value.branches, "branch_1")
      ),
      barBranch2Reserved = computed(() =>
        getBarBranchesData(ageData.value.branches, "branch_2")
      ),
      barBranch3Reserved = computed(() =>
        getBarBranchesData(ageData.value.branches, "branch_3")
      ),
      barBranch4Reserved = computed(() =>
        getBarBranchesData(ageData.value.branches, "branch_4")
      ),
      dominateBranchesLabels = computed(() =>
        getBranchesLabels(genderData.value.dominateBranches)
      ),
      growingBranchesLabels = computed(() =>
        getBranchesLabels(genderData.value.growingBranches)
      ),
      dominateBranchesKeys = computed(() =>
        getBranchesKeys(genderData.value.dominateBranches)
      ),
      growingBranchesKeys = computed(() =>
        getBranchesKeys(genderData.value.growingBranches)
      ),
      barDominateBranchesCountData1 = computed(() =>
        getBranchesCountData(
          genderData.value.dominateBranches[group1.value].counts,
          dominateBranchesKeys.value
        )
      ),
      barDominateBranchesCountData2 = computed(() =>
        getBranchesCountData(
          genderData.value.dominateBranches[group2.value].counts,
          dominateBranchesKeys.value
        )
      ),
      barGrowingBranchesCountData1 = computed(() =>
        getBranchesCountData(
          genderData.value.growingBranches[group1.value].counts,
          growingBranchesKeys.value
        )
      ),
      barGrowingBranchesCountData2 = computed(() =>
        getBranchesCountData(
          genderData.value.growingBranches[group2.value].counts,
          growingBranchesKeys.value
        )
      ),
      barDominateBranchesAverageData = computed(() =>
        getBranchesAverageData(genderData.value.dominateBranches)
      ),
      barGrowingBranchesAverageData = computed(() =>
        getBranchesAverageData(genderData.value.growingBranches)
      ),
      barDominateBranchesAverageDataReserved = computed(() =>
        getBranchesAverageData(ageData.value.dominateBranches)
      ),
      barGrowingBranchesAverageDataReserved = computed(() =>
        getBranchesAverageData(ageData.value.growingBranches)
      ),
      barDominateBranchesRespondentsCountData1 = computed(() =>
        getBranchesRespondentsCount(
          genderData.value.dominateBranches[group1.value]
            .respondentsDistributionByLevels
        )
      ),
      barDominateBranchesRespondentsCountData2 = computed(() =>
        getBranchesRespondentsCount(
          genderData.value.dominateBranches[group2.value]
            .respondentsDistributionByLevels
        )
      ),
      barGrowingBranchesRespondentsCountData1 = computed(() =>
        getBranchesRespondentsCount(
          genderData.value.growingBranches[group1.value]
            .respondentsDistributionByLevels
        )
      ),
      barGrowingBranchesRespondentsCountData2 = computed(() =>
        getBranchesRespondentsCount(
          genderData.value.growingBranches[group2.value]
            .respondentsDistributionByLevels
        )
      ),
      barHarmoniusData1 = computed(() => {
        let newData = [
          {
            label: "Гармонически профили",
            data: [],
          },
        ];
        checkScores(
          newData[0].data,
          genderData.value.harmonious[group1.value].levels
        );
        return newData;
      }),
      barHarmoniusData2 = computed(() => {
        let newData = [
          {
            label: "Гармонически профили",
            data: [],
          },
        ];
        checkScores(
          newData[0].data,
          genderData.value.harmonious[group2.value].levels
        );
        return newData;
      }),
      barHarmoniusDataReserved = computed(() => {
        const harmonius = ageData.value.harmonious;
        let newData = [];
        for (let group in harmonius) {
          newData.push({
            label: `Гармонические профили, ${group}`,
            data: [],
          });
          checkScores(
            newData[newData.length - 1].data,
            harmonius[group].levels
          );
        }
        return newData;
      });

    const getBarTotalData = (total) => {
      let newData = [];
      for (let group in total) {
        newData.push({ label: group, data: [] });
        checkScores(newData[newData.length - 1].data, total[group][0]);
      }
      return newData;
    };
    const getBarDomainsData = (domains, label, number) => {
      let newData = [];
      for (let group in domains) {
        newData.push({ label: `${label} (${group})`, data: [] });
        checkScores(
          newData[newData.length - 1].data,
          domains[group][`domain_${number}`]
        );
      }
      return newData;
    };
    const getDataOfGroupDominateDomains = (dominateDomains, number) => {
      const keys = Object.keys(dominateDomains);
      return Object.values(dominateDomains[keys[number]][0]);
    };
    const getBarDominateDomains = (domains) => {
      const newData = [];
      for (let group in domains) {
        newData.push({
          label: `Опытный домен, среднее (${group})`,
          data: [],
        });
        newData[newData.length - 1].data.push(
          domains[group][1]["dominate_domain_1_average"]["domain_1"]
        );
        newData[newData.length - 1].data.push(
          domains[group][1]["dominate_domain_1_average"]["domain_2"]
        );
        // newData[newData.length - 1].data.push(
        //   domains[group][1]["without_dominate_domain_average"]["domain_2"]
        // );
        newData.push({
          label: `Стратегический домен, среднее (${group})`,
          data: [],
        });
        newData[newData.length - 1].data.push(
          domains[group][1]["dominate_domain_2_average"]["domain_1"]
        );
        newData[newData.length - 1].data.push(
          domains[group][1]["dominate_domain_2_average"]["domain_2"]
        );
      }
      return newData;
    };
    const getBarBranchesData = (branches, branch) => {
      let newData = [];
      for (let group in branches) {
        newData.push({ label: `${dictionary[branch]} (${group})`, data: [] });
        checkScores(newData[newData.length - 1].data, branches[group][branch]);
      }
      return newData;
    };
    const getBranchesCountData = (counts, keys) => {
      let newData = [
        {
          label: "Количество сотрудников",
          data: [],
        },
      ];
      for (let key of keys) {
        counts[key]
          ? newData[0].data.push(counts[key])
          : newData[0].data.push(0);
      }
      return newData;
    };
    const getDominateBranchesDataReserved = (branches, branch) => {
      const newData = [];
      for (let group in branches) {
        const temp = [branches[group].counts[branch]];
        newData.push({ label: group, data: temp });
      }
      return newData;
    };
    const getBranchesLabels = (focusBranches) => {
      const branches = focusBranches;
      let set = new Set();
      for (let group in branches) {
        keysToLabels(Object.keys(branches[group]["counts"])).forEach((key) =>
          set.add(key)
        );
      }
      return Array.from(set);
    };

    const getBranchesKeys = (focusBranches) => {
      const branches = focusBranches;
      let set = new Set();
      for (let group in branches) {
        Object.keys(branches[group]["counts"]).forEach((key) => set.add(key));
      }
      return Array.from(set);
    };
    const getBranchesAverageData = (branches) => {
      const newData = [];
      const average = branches,
        keys = Object.keys(branchesDictionary);
      for (let group in average) {
        newData.push({
          label: `Средние значения (${group})`,
          data: [],
        });
        for (let key of keys) {
          if (average[group].average[key]) {
            newData[newData.length - 1].data.push(
              average[group].average[key][key]
            );
          } else {
            newData[newData.length - 1].data.push(0);
          }
        }
      }
      return newData;
    };
    const getBranchesRespondentsCount = (branches) => {
      const respondents = branches;
      const newData = [];
      for (let level of levelDictionary) {
        newData.push({
          label: level,
          data: [0, 0, 0, 0],
        });
      }
      for (let bigBranch in respondents) {
        for (let branch in respondents[bigBranch]) {
          const index = branch.slice(-1),
            branchValues = respondents[bigBranch][branch];
          for (let value in branchValues) {
            if (value <= 69) newData[0].data[index - 1] += branchValues[value];
            else if (value <= 89)
              newData[1].data[index - 1] += branchValues[value];
            else if (value <= 109)
              newData[2].data[index - 1] += branchValues[value];
            else if (value <= 129)
              newData[3].data[index - 1] += branchValues[value];
            else newData[4].data[index - 1] += branchValues[value];
          }
        }
      }
      return newData;
    };
    const getBranchesRespondentsCountReserved = (branches, currentBranch) => {
      const respondents = branches;
      const newData = [];
      for (let level of levelDictionary) {
        newData.push({
          label: level,
          data: [0, 0, 0, 0],
        });
      }
      for (let group in respondents) {
        for (let bigBranch in respondents[group]
          .respondentsDistributionByLevels) {
          for (let branch in respondents[group].respondentsDistributionByLevels[
            bigBranch
          ]) {
            const index = branch.slice(-1),
              branchValues =
                respondents[group].respondentsDistributionByLevels[bigBranch][
                  branch
                ];
            if (branch == currentBranch) {
              for (let value in branchValues) {
                if (value <= 69)
                  newData[0].data[index - 1] += branchValues[value];
                else if (value <= 89)
                  newData[1].data[index - 1] += branchValues[value];
                else if (value <= 109)
                  newData[2].data[index - 1] += branchValues[value];
                else if (value <= 129)
                  newData[3].data[index - 1] += branchValues[value];
                else newData[4].data[index - 1] += branchValues[value];
              }
            }
          }
        }
      }
      return newData;
    };

    const print = () => window.print();

    return {
      dictionary,
      levelDictionary,
      keysToLabels,
      genderData,
      descriptions,
      barTotalData,
      barTotalDataReserved,
      barDomain1Data,
      barDomain2Data,
      barDomain1DataReserved,
      barDomain2DataReserved,
      getDataOfGroupDominateDomains,
      doughnutDominateDomains1,
      doughnutDominateDomains2,
      doughnutDominateDomains1Reserved,
      doughnutDominateDomains2Reserved,
      doughnutDominateDomains3Reserved,
      doughnutDominateDomains4Reserved,
      barDominateDomains,
      barDominateDomainsReserved,
      getBarBranchesData,
      barBranch1,
      barBranch2,
      barBranch3,
      barBranch4,
      barBranch1Reserved,
      barBranch2Reserved,
      barBranch3Reserved,
      barBranch4Reserved,
      dominateBranchesLabels,
      group1,
      group2,
      dominateBranchesKeys,
      getBarTotalData,
      getBarDomainsData,
      getBarDominateDomains,
      getBranchesCountData,
      getDominateBranchesDataReserved,
      getBranchesRespondentsCountReserved,
      barDominateBranchesCountData1,
      barDominateBranchesCountData2,
      barDominateBranchesAverageData,
      barDominateBranchesAverageDataReserved,
      barGrowingBranchesAverageDataReserved,
      getBranchesLabels,
      getBranchesKeys,
      growingBranchesLabels,
      growingBranchesKeys,
      getBranchesAverageData,
      barGrowingBranchesCountData1,
      barGrowingBranchesCountData2,
      barGrowingBranchesAverageData,
      branchesDictionary,
      getBranchesRespondentsCount,
      barDominateBranchesRespondentsCountData1,
      barDominateBranchesRespondentsCountData2,
      barGrowingBranchesRespondentsCountData1,
      barGrowingBranchesRespondentsCountData2,
      barHarmoniusData1,
      barHarmoniusData2,
      barHarmoniusDataReserved,
      ageData,

      age1,
      age2,
      age3,
      age4,

      print,
    };
  },
};
</script>

<style lang="scss" scoped>
.complex-chart {
  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &__element {
    flex-basis: 50%;
    p {
      font-weight: 600;
      font-size: 17px;
      text-align: center;
    }
  }

  @media (max-width: 1270px) {
    &__container {
      flex-direction: column;
    }
    &__element {
      flex: 1;
      width: 100%;
      p {
        text-align: center;
      }
    }
  }
}
.three-block {
  width: 100%;
  grid-template-rows: auto auto;
  grid-template-columns: 37% 26% 37%;
  // grid-template-areas: ;
  p {
    text-align: center;
    font-weight: 600;
    margin-bottom: 15px;
  }

  &__legend {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 30px;
    justify-content: space-around;

    p {
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      max-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 90%;
      white-space: nowrap;
    }
  }

  @media (max-width: 1380px) {
    grid-template-columns: auto;
    // grid-template-rows: repeat(5, auto);
    grid-template-areas: "label1" "chart1" "label2" "chart2";
    &__label1 {
      grid-area: label1;
    }
    &__label2 {
      grid-area: label2;
    }
    &__chart1 {
      grid-area: chart1;
    }
    &__chart2 {
      grid-area: chart2;
    }
    p {
      margin-bottom: 10px;
    }
    &__legend {
      display: none;
    }
  }
}
.multiple-charts {
  &__charts {
    flex: 1;
  }
  &__legend {
    align-self: center;
  }
  &__labels {
    font-size: 15px;
    max-width: 150px;
  }
  &__mobile-labels {
    display: none;
  }

  @media (max-width: 1400px) {
    flex-direction: column-reverse;
    &__labels {
      display: none;
    }
    &__mobile-labels {
      display: block;
    }
  }
}
.multiple-charts.multiple-row {
  .multiple-charts__charts {
    @media (max-width: 1400px) {
      flex-direction: column;
    }
  }
}
</style>
