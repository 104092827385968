<template>
  <div class="chart-consultation">
    Для подробной аналитики запросите <button @click="showConsultModal" class="chart-consultation__link">консультацию специалиста</button>
  </div>
  <pop-up-form title="Получить консультацию по продукту"
                 productName="тесту"
                 v-if="openConsultModal"
                 @closeModal="openConsultModal = false" />
</template>

<script>
import PopUpForm from "../../../../components/Popups/PopUpForm.vue";
import {ref} from "vue";

export default {
    name: "chart-consultation",
    components: {PopUpForm},
    setup() {
        const openConsultModal = ref(false)
        const showConsultModal = () => {
            openConsultModal.value = !openConsultModal.value
        }

        return {
            showConsultModal,
            openConsultModal
        }
    }
}
</script>

<style lang="scss" scoped>
.chart-consultation{
    font-weight: 300;
    background-color: white;
    border: 1px solid var(--main-color);
    
    // color: white;
    padding: 5px 10px;
    margin: 35px 0 10px 0;
    &__link{
        color: var(--main-color);
        font-weight: 400;

        &:hover{
            color: var(--hover-color);
        }
    }
}
</style>