<template>
  <div class="bar-block w-full">
    <div
      :class="`
        chart-block
        w-full
        flex flex-column
        align-item-center
        overflow--auto ${vertical ? 'complex' : ''}
        ${fullWidth ? 'full-width' : ''}`"
      :style="`min-height: ${currentHeight}px`"
    >
      <div v-if="label" class="chart-label">
        {{ label }}
      </div>
      <BarChart
        :chartData="chartFinalData"
        :width="chartWidth"
        :height="chartHeight"
        :options="charOptions"
        :plugins="plugins"
        ref="barInstance"
      ></BarChart>
    </div>
  </div>
</template>

<script>
import { BarChart } from "vue-chart-3";
import { ref, toRefs } from "@vue/reactivity";
import { computed, onBeforeMount, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import useHtmlLegend from "./../mixins/useHtmlLegend";
export default {
  name: "bar-chart",
  components: { BarChart },
  props: {
    label: {
      type: String,
      required: false,
    },
    chartLabels: {
      type: Array,
    },
    chartData: {
      type: Object,
    },
    legend: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    reverseX: {
      type: Boolean,
      default: false,
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    ticksShow: {
      type: Boolean,
      default: true,
    },
    responsive: {
      type: Boolean,
      default: true,
    },
    legendPosition: {
      type: String,
      default: "right",
    },
    htmlLegend: {
      type: String,
      default: "",
    },
    minHeight: {
      type: Number,
      default: 400,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore(),
      { htmlLegendPlugin } = useHtmlLegend();
    const barProps = toRefs(props);
    const chartFinalData = ref(null),
      charOptions = ref({
        indexAxis: "x",
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            maxWidth: 500,
            position: "right",
            display: true,
            labels: {
              padding: 25,
              color: "#545454",
              font: {
                size: 14,
              },
            },
          },
        },
        scales: {
          x: {
            ticks: {
              display: true,
            },
            reverse: false,
            stacked: false,
          },
          y: {
            beginAtZero: false,
            suggestedMin: 50,
            ticks: {
              display: true,
              precision: 0,
            },
            stacked: false,
          },
        },
      }),
      processingData = ref([]),
      plugins = ref([]),
      chartWidth = ref(700),
      chartHeight = ref(400),
      colors = [
        "#2cc185",
        "#40c9d0",
        "#52dba4",

        "#2CB3C1",
        "#2C68C1",
        "#3B2CC1",
        "#C12CB3",
      ],
      barInstance = ref();
    const winWidth = computed(() => store.state.width),
      currentHeight = computed(() =>
        winWidth.value < 600 && barProps.minHeight.value > 250
          ? 300
          : barProps.minHeight.value
      );

    onBeforeMount(() => {
      charOptions.value.plugins.legend.display = barProps.legend.value;
      if (barProps.vertical.value) charOptions.value.indexAxis = "y";
      if (barProps.reverseX.value) charOptions.value.scales.x.reverse = true;
      if (barProps.stacked.value) {
        charOptions.value.scales.x.stacked = true;
        charOptions.value.scales.y.stacked = true;
      }
      if (!barProps.ticksShow.value)
        charOptions.value.scales.y.ticks.display = false;
      if (barProps.responsive.value) charOptions.value.responsive = true;
      if (barProps.htmlLegend.value) {
        charOptions.value.plugins.htmlLegend = {};
        charOptions.value.plugins.htmlLegend.containerID =
          barProps.htmlLegend.value;
        plugins.value.push(htmlLegendPlugin);
      }
      charOptions.value.plugins.legend.position = barProps.legendPosition.value;

      if (window.innerWidth < 1038) {
        if (!barProps.vertical.value)
          charOptions.value.scales.x.ticks.display = false;
      }

      let dataset = [];
      barProps.chartData.value.forEach((el, index) => {
        dataset.push({
          data: el.data,
          label: el.label,
          backgroundColor: colors[index],
        });
      });

      chartFinalData.value = {
        labels: barProps.chartLabels.value,
        datasets: dataset,
      };
    });

    watch(winWidth, () => {
      if (winWidth.value < 1038 && chartWidth.value != 320) {
        if (!barProps.vertical.value)
          charOptions.value.scales.x.ticks.display = false;
        // chartWidth.value = 320
        // chartHeight.value = 250
      }
      if (winWidth.value >= 1038 && chartWidth.value != 700) {
        charOptions.value.scales.x.ticks.display = true;
        // chartWidth.value = 700
        // chartHeight.value = 400
      }
    });

    return {
      chartFinalData,
      charOptions,
      processingData,
      winWidth,
      chartWidth,
      chartHeight,
      colors,
      barInstance,
      plugins,
      currentHeight,
    };
  },
};
</script>

<style lang="scss" scoped>
.bar-block {
  padding: 20px;
}

.chart-block {
  & > div {
    width: 100%;
    flex: 1;
  }

  &.full-width {
    width: 100%;
  }
}

.chart-label {
  display: none;
  text-align: center;
  margin-bottom: 15px;
}

@media (max-width: 1400px) {
  .chart-label {
    display: block;
  }
}

@media (max-width: 1038px) {
  .bar-block {
    padding: 20px 0;
  }
}
</style>