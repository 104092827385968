<template>
  <div class="report-options flex flex-column">
    <p class="card-header-divider">
      Настройки аналитики при повторном тестировании
    </p>
    <div class="report-options__body">
      <custom-select-2
        label="Группа"
        name="group_id"
        v-model="group"
        :options="groupOptions"
      ></custom-select-2>
    </div>
    <div v-if="group" class="report-options__btn flex justify-content-center">
      <button @click="requestForReport" class="btn btn-accent">
        Получить отчет
      </button>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from "@vue/runtime-core";
import CustomSelect2 from "../../../components/Forms/Fields/CustomSelect2.vue";
import { useStore } from "vuex";
export default {
  components: { CustomSelect2 },
  name: "repeated-report-parametres",
  setup(props, context) {
    const store = useStore();
    const group = ref(null),
      groupOptions = ref([]);
    const groups = computed(() => store.state.group.groups);

    onBeforeMount(() => {
      store.dispatch("group/getGroups").then(() => {
        groupOptions.value = groups.value.map((group) => {
          return { id: group.id, text: group.name };
        });
      });
    });

    const requestForReport = () => {
      context.emit("reportRequest", {
        group_id: group.value,
      });
    };

    return {
      group,
      groupOptions,
      groups,
      requestForReport,
    };
  },
};
</script>
