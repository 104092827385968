<template>
  <div class="group-card">
    <div class="group-card__head">
      <p>Средний ЭИ-профиль</p>
      <p>{{ name }}</p>
    </div>
    <div class="group-card__body">
      <div class="group-card__section">
        <p>Общий уровень эмоционального интеллекта</p>
        <score-scale
          label="Общий ЭИ"
          :state="scalesStates[0]"
          :score="groupData.total"
        />
      </div>
      <div class="group-card__section">
        <p>Домены эмоционального интеллекта</p>
        <score-scale
          label="Опытный ЭИ"
          :state="scalesStates[1]"
          :score="groupData.domain_1"
        />
        <score-scale
          label="Стратегический ЭИ"
          :state="scalesStates[2]"
          :score="groupData.domain_2"
        />
      </div>
      <div class="group-card__section">
        <p>Шкалы эмоционального интеллекта</p>
        <score-scale
          label="Распознание эмоций"
          :state="scalesStates[3]"
          :score="groupData.branch_1"
        />
        <score-scale
          label="Использование эмоций"
          :state="scalesStates[4]"
          :score="groupData.branch_2"
        />
        <score-scale
          label="Понимание эмоций"
          :state="scalesStates[5]"
          :score="groupData.branch_3"
        />
        <score-scale
          label="Управление эмоциями"
          :state="scalesStates[6]"
          :score="groupData.branch_4"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { toRef } from "@vue/reactivity";
import ScoreScale from "./details/ScoreScale.vue";
export default {
  components: { ScoreScale },
  name: "group-card",
  props: {
    name: {
      type: String,
      default: "Название группы",
    },
    groupData: {
      type: Object,
    },
    scalesStates: {
      type: Array,
      default: () => ["", "", "", "", "", "", ""],
    },
  },
  setup(props) {
    const data = toRef(props, "groupData");

    return {
      data,
    };
  },
};
</script>

<style lang="scss" scoped>
.group-card {
  padding: 25px;
  background-color: #f9f9f9;

  &__head {
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(230, 230, 230);
    p {
      margin-bottom: 10px;
    }
  }

  &__body {
  }

  &__section {
    p {
      font-size: 17px;
      font-weight: 400;
      margin-bottom: 15px;
    }

    div {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 950px) {
    &__head {
      font-size: 16px;
    }

    &__section {
      p {
        font-size: 14px;
      }
    }
  }
}
</style>