<template>
  <div class="pie-block w-full">
    <div
      class="pie-charts chart-block w-full flex flex-column align-item-center"
    >
      <div v-if="label" class="pie-charts__chart-label chart-label">
        {{ label }}
      </div>
      <PieChart
        ref="pieInstance"
        :chartData="chartFinalData"
        :width="250"
        :options="charOptions"
        :plugins="plugins"
      ></PieChart>
    </div>
  </div>
</template>

<script>
import { PieChart } from "vue-chart-3";
import { ref, toRefs } from "@vue/reactivity";
import { computed, onBeforeMount } from "@vue/runtime-core";
import { useStore } from "vuex";
import useHtmlLegend from "./../mixins/useHtmlLegend";
import useEmptyDoughnutPlugin from "../mixins/useEmptyDoughnutPlugin";
export default {
  name: "pie-chart",
  components: { PieChart },
  props: {
    label: {
      type: String,
      required: false,
    },
    chartLabels: {
      type: Array,
    },
    chartData: {
      type: Array,
    },
    legend: {
      type: Boolean,
      default: false,
    },
    legendPosition: {
      type: String,
      default: "top",
    },
    htmlLegend: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const pieProps = toRefs(props),
      store = useStore(),
      { htmlLegendPlugin } = useHtmlLegend(),
      { emptyDoughnutPlugin } = useEmptyDoughnutPlugin();
    const chartFinalData = ref(null),
      charOptions = ref({
        plugins: {
          emptyDoughnut: {
            color: "rgb(236, 238, 239)",
            width: 3,
            radiusDecrease: 20,
          },
          legend: {
            display: false,
            maxWidth: 500,
            position: "right",
            labels: {
              padding: 20,
              color: "#545454",
              font: {
                size: 14,
              },
            },
          },
        },
        // aspectRatio: 1
      }),
      chartHeight = ref(450),
      pieInstance = ref(),
      plugins = ref([emptyDoughnutPlugin]);

    const winWidth = computed(() => store.state.width);

    onBeforeMount(() => {
      charOptions.value.plugins.legend.position = pieProps.legendPosition.value;
      charOptions.value.plugins.legend.display = pieProps.legend.value;
      if (pieProps.htmlLegend.value) {
        charOptions.value.plugins.htmlLegend = {};
        charOptions.value.plugins.htmlLegend.containerID =
          pieProps.htmlLegend.value;
        plugins.value.push(htmlLegendPlugin);
      }

      chartFinalData.value = {
        labels: pieProps.chartLabels.value,
        datasets: [
          {
            data: pieProps.chartData.value,
            backgroundColor: [
              "#34c98d",
              "#40c9d0",
              "#52dba4",

              "#2CB3C1",
              "#2C68C1",
              "#3B2CC1",
              "#C12CB3",
            ],
          },
        ],
      };
    });

    // watch(winWidth, () => {
    //   if (
    //     winWidth.value < 1038 &&
    //     charOptions.value.plugins.legend.position != "top"
    //   ) {
    //     charOptions.value.plugins.legend.position = "top";
    //     charOptions.value.plugins.legend.labels.font.size = 12;
    //     charOptions.value.plugins.legend.maxWidth = 300;
    //     chartHeight.value = 700;
    //     // pieInstance.value.chartInstance.resize(1000, 700)
    //     pieInstance.value.chartInstance.render();
    //   }
    //   if (
    //     winWidth.value >= 1038 &&
    //     charOptions.value.plugins.legend.position != "right"
    //   ) {
    //     charOptions.value.plugins.legend.position = "right";
    //     charOptions.value.plugins.legend.labels.font.size = 14;
    //     charOptions.value.plugins.legend.maxWidth = 500;
    //     // pieInstance.value.chartInstance.resize(800, 450)
    //     chartHeight.value = 450;
    //     pieInstance.value.chartInstance.render();
    //   }
    // });

    return {
      chartFinalData,
      charOptions,
      winWidth,
      chartHeight,
      pieInstance,
      plugins,
    };
  },
};
</script>

<style lang="scss" scoped>
.pie-block {
  .pie-charts {
    margin-top: 20px;
    min-height: 250px;
    margin-bottom: 25px;

    width: 30%;
    &__chart-label {
      margin-bottom: 10px;
    }
  }
}
</style>